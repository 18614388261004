<template>
  <q-table
    flat bordered
    :rows="channels"
    :rows-per-page-options="[15,50,100]"
    :dense="$q.screen.lt.md"
    :columns="channelColumns"
    row-key="id"
    separator="cell"
    :loading="loading"
    :filter="filter"
    @request="onChannelRequest"
    :pagination="pagination"
    :updateChannel="updateChannel"
    @update:pagination="$emit('update:pagination', $event)"
  >
  <template v-slot:body-cell-totalCount="props">
        <q-td :props="props">
          <div class="row items-center justify-center w-full">
            <a class="text-purple no-decoration"
              :href="`https://app.openmeasures.io/timeline?searchTerm=channelusername:${props.row.username}&websites=telegram&interval=day&startDate=${moment.utc().subtract(1, 'months').format('YYYY-MM-DD')}&endDate=${moment.utc().format('YYYY-MM-DD')}&numberOf=10&changepoint=false&esquery=query_string`"
              target="_blank">
              View Timeseries
            </a>
          </div>
        </q-td>
    </template>
    <template v-slot:header-cell-totalCount="props">
        <q-th :props="props" class="row items-center justify-center">
        <div class="row items-center">
          Timeline
          <IconTool class="q-ml-sm" tool="timeline" :size="30"/>
        </div>
      </q-th>
      </template>
  <template v-slot:body-cell-source="props">
    <q-td :props="props">
        <WebsiteIcon :website="props.row.source" class="website-icon" />
        {{ props.row.source }}
    </q-td>
  </template>
  <template v-slot:body-cell-isActive="props">
    <q-td :props="props">
      <div class="icon-container">
        <q-btn flat
          :icon="hoveredRow === props.row.id ? 'delete' : 'delete'"
          :color="hoveredRow === props.row.id ? 'red' : 'black'"
          @mouseover="hoveredRow = props.row.id"
          @mouseleave="hoveredRow = null"
          @click="deleteRow(props.row)"
        />
        <q-tooltip>
        Delete channel from monitoring
      </q-tooltip>
      </div>
    </q-td>
  </template>
  <template v-slot:top-left>
      <q-btn color="black" :disable="loading || !capacityAvailable" label="Add Channels" icon-right="add"  @click="openForm"></q-btn>
      <q-tooltip v-if="!capacityAvailable">
        Your account has a maximum number of allowed crawl requests. Please contact info@openmeasures.io to request more
      </q-tooltip>
      <q-linear-progress size="22px" :indeterminate="loading" :value="this.totalChannelsPercentage" color="accent" class="q-mt-sm">
        <q-tooltip>
        Channels only count toward the maximum if they are active. Inactive channels do not count toward the maximum.
      </q-tooltip>
        <div class="absolute-full flex flex-center">
        <q-badge color="white" text-color="black" :label="this.totalChannelsLabel"></q-badge>
      </div>
      </q-linear-progress>
    <q-dialog
      :model-value="showForm"
      @update:model-value="$emit('update:showForm', $event)"
    >
    <q-card>
      <q-card-section style="margin-right: 200px;">
        <div class="text-h6">
          <WebsiteIcon website="telegram" class="website-icon margin-right" />
          Add Channels
        </div>
      </q-card-section>
      <q-card-section>
        Request Telegram channel crawling.
        Multiple channels can be added by separating them with a new line.
      </q-card-section>
      <q-card-section>
        <q-form @submit="addChannelRequest">
        <q-input autogrow outlined
          color="black"
          class="scroll"
          :model-value="newChannel"
          @update:model-value="$emit('update:newChannel', $event)"
          label="Channel"
          error-message="Please remove spaces from channel names"
          :error="!isValid"
        />
        <q-btn
          :disable="newChannel === '' || !inputIsValid || !capacityAvailable"
          type="submit"
          class="margin-top"
          icon-right="add"
          color="black"
          :loading="loading"
        >
          Add {{ numNewChannels }} channels ({{ totalChannelsRemaining }} remaining)
          <template v-slot:loading>
            <q-spinner-grid></q-spinner-grid>
          </template>
        </q-btn>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>
    </template>
    <template v-slot:no-data="{ message }">
      <div class="full-width row flex-center text-accent q-gutter-sm">
        <span>
          {{ message }}
        </span>
      </div>
    </template>
    <template v-slot:top-right>
      <q-input
        borderless
        dense
        debounce="1000"
        :model-value="filter"
        @update:model-value="$emit('update:filter', $event)"
        :disable="loading"
        placeholder="Search"
      >
        <template v-slot:append>
          <q-icon name="search"></q-icon>
        </template>
        <q-tooltip>
          Search for requested channels
        </q-tooltip>
      </q-input>
    </template>
    <template v-if="isAdmin" v-slot:body-cell-toggleStatus="props">
      <q-td :props="props">
        <q-toggle
            v-model="props.row.isActive"
            color="purple"
            checked-icon="check"
            unchecked-icon="clear"
            @update:model-value="updateChannel(props.row)"
          >
          <q-tooltip>
          Turn channel crawl request on or off
          </q-tooltip>
      </q-toggle>
      </q-td>
    </template>
    <template v-else v-slot:body-cell-toggleStatus="props">
      <q-td :props="props">
        <div class="text-center">
        <q-icon name="done_round" color="green" v-if="props.row.isActive" />
        <q-icon name="clear_round" color="red" v-else />
        </div>
      </q-td>
    </template>
    <template v-slot:header-cell-toggleStatus="props">
      <q-th :props="props">
        {{ props.col.label }}
        <q-icon name="info_outline" color="purple"></q-icon>
        <q-tooltip>
          Channels show as active when the crawlers were able to discover them. If the channel is not active, it may not exist or the crawler was unable to find it.
        </q-tooltip>
        </q-th>
    </template>
    <template v-if="isAdmin" v-slot:body-cell-toggleMedia="props">
      <q-td :props="props">
        <q-toggle
            v-model="props.row.mediaActive"
            color="purple"
            checked-icon="check"
            unchecked-icon="clear"
            @update:model-value="updateChannel(props.row)"
          >
          <q-tooltip>
          Turn media crawling on or off
        </q-tooltip>
        </q-toggle>
      </q-td>
    </template>
  </q-table>
</template>

<script>
import WebsiteIcon from '@/components/WebsiteIcon'
import moment from 'moment'
import { doTruncateId, doTruncate, onRowClick } from '@/helpers/crawlRequests'
import IconTool from '@/components/input/tool-picker/IconTool.vue'

export default {
  components: {
    WebsiteIcon,
    IconTool,
  },
  props: [
    'loading',
    'channels',
    'channelColumns',
    'filter',
    'onChannelRequest',
    'pagination',
    'totalChannelsLabel',
    'totalChannelsPercentage',
    'totalChannelsRemaining',
    'showForm',
    'dropdown',
    'capacityAvailable',
    'openForm',
    'isAdmin',
    'numNewChannels',
    'newChannel',
    'sites',
    'addChannel',
    'allSites',
    'countDropdown',
    'timeDuration',
    'isValid',
    'inputIsValid',
    'addChannelRequest',
    'updateTimeDurationChannels',
    'deleteRow',
    'updateChannel',
  ],
  data () {
    return {
      hoveredRow: null,
      moment
    }
  },
  methods: {
    doTruncateId,
    doTruncate,
    onRowClick,
  }
}
</script>

<style scoped lang="scss">
.banner {
  width: 100%;
  background-color: var(--color-blue);  /* Change this to your preferred color */
  color: #212529;  /* Change this to your preferred color */
  text-align: center;
  padding: 10px 0;
  font-family: var(--primary-font);
  font-size: 15px;
}

.icon-container:hover .q-btn.trash-icon {
  visibility: visible;
}

.q-btn.trash-icon {
  visibility: hidden;
}
.add-keyword-button {
  margin-bottom: 20px;
}

$page-padding: 40px;
table {
  width: 100%;
  border-collapse: collapse;
}

button {
  background: none;
  border: none;
  cursor: pointer;

}

.home-header {
  padding: $page-padding var(--spacing-side-padding);
  margin-bottom: 24px;
  width: calc(100vw - 2 * var(--spacing-side-padding));
}

.header {
  display: flex;
  justify-content: space-between;
}
.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.margin-right {
  margin-right: 10px; /* Adjust as needed */
}

.margin-top {
  margin-top: 10px; /* Adjust as needed */
}

</style>